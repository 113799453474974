import { usePathname, useRouter } from "next/navigation"
import * as React from "react"
import { find, keys, pipe } from "@fxts/core"

import { useProfileStore } from "@/store/profiles/store"
import { AuthorizedFeatureRole } from "@/features/auth/models"

const PATHNAME_PERMISSION: Record<string, AuthorizedFeatureRole> = {
  "/issuance": "TAX_INVOICE_ISSUE_REQUEST",
  "/invoices": "PURCHASED_TAX_INVOICE_FETCH",
  "/request-form": "ISSUANCE_REQUEST_FORM_FETCH",
  "/customers": "TAX_INVOICE_ISSUE_REQUEST",
  "/received-invoices": "RECEIVED_TAX_INVOICE_FETCH",
  "/received-invoices/requests": "TAX_INVOICE_ISSUANCE_REQUEST_MANAGEMENT",
  "/transactions": "BANK_ACCOUNT_TRANSACTION_FETCH",
  "/request-payment/templates": "PAYMENT_REQUEST_MANAGEMENT",
  "/request-payment/codes": "PAYMENT_REQUEST_MANAGEMENT",
  "/settings/workspace": "COMPANY_DETAIL_MANAGEMENT",
  "/settings/certification": "CERTIFICATE_MANAGEMENT",
  "/billing": "BILLING_MANAGEMENT",
  "/team/roles": "COLLABORATOR_AND_ROLE_MANAGEMENT",
  "/team/members": "COLLABORATOR_INVITATION",
}

const FALLBACK_ROUTES = {
  TAX_INVOICE_ISSUE_REQUEST: "/issuance",
  PURCHASED_TAX_INVOICE_FETCH: "/invoices",
  ISSUANCE_REQUEST_FORM_FETCH: "/request-form",
  BANK_ACCOUNT_TRANSACTION_FETCH: "/transactions",
}

export type UseFeatureAuthorizationProps = {
  /**
   * 권한이 없는 경우 진입을 막을지에 대한 여부
   */
  enabledBlocker?: boolean
}

export function useFeatureAuthorization({
  enabledBlocker,
}: UseFeatureAuthorizationProps = {}) {
  const router = useRouter()
  const pathname = usePathname()
  const authorizedFeatures = useProfileStore(state => state.getAuthorizedFeatures())

  React.useEffect(() => {
    if (!enabledBlocker) {
      return
    }

    const needsPermission = PATHNAME_PERMISSION[pathname]
    if (needsPermission && !authorizedFeatures.includes(needsPermission)) {
      const availableFallback = pipe(
        keys(FALLBACK_ROUTES),
        find(feature => authorizedFeatures.includes(feature)),
      )
      if (availableFallback) {
        const fallbackRoute = FALLBACK_ROUTES[availableFallback]
        router.replace(fallbackRoute ?? "/request-payment/requests")
      }
    }
  }, [authorizedFeatures, pathname, router, enabledBlocker])

  const hasPermissionBy = React.useCallback(
    (role: AuthorizedFeatureRole) => {
      return authorizedFeatures.some(feature => feature === role)
    },
    [authorizedFeatures],
  )

  return {
    hasPermissionBy,
  } as const
}
