import { create } from "zustand"
import { persist } from "zustand/middleware"

import {
  ProfileInvitationModel,
  ProfileModel,
  ProfileWorkspaceCertificationModel,
  ProfileWorkspaceGroupModel,
  ProfileWorkspaceModel,
} from "@/features/profiles"
import { AuthorizedFeatureRole } from "@/features/auth"

interface ProfileState {
  profile: ProfileModel | null
  workspaceGroup: ProfileWorkspaceGroupModel | null
  setProfile: (profile: ProfileModel | null) => void
  getProfileAccountId: () => number
  setWorkspaceGroup: (workspaceGroup: ProfileWorkspaceGroupModel | null) => void
  getProfileWorkspaceGroups: () => ProfileWorkspaceGroupModel[]
  getWorkspaceCertificates: () => ProfileWorkspaceCertificationModel[]
  getInvitations: () => ProfileInvitationModel[]
  getCurrentWorkspace: () => ProfileWorkspaceModel | null
  getCurrentWorkspaceId: () => number
  getAuthorizedFeatures: () => AuthorizedFeatureRole[]
  hasAuthorizedFeature: (feature: AuthorizedFeatureRole) => boolean
}

/**
 * @deprecated 독립적인 profile global store을 운영/유지보수 할 이유가 없습니다.
 * 어차피 대시보드 전/후에 필요한 api로서 계속 호출되어 관리가 필요하기 때문에, persist될 필요도 없습니다.
 * 오히려 persist되고, profileStore를 업데이트하지 않을 경우에 대한 문제가 생기기 때문에 삭제예정입니다.
 */
export const useProfileStore = create<ProfileState>()(
  persist(
    (set, get) => ({
      profile: null,
      workspaceGroup: null,
      setProfile: profile => set({ profile }),
      getProfileAccountId: () => {
        return get().profile?.account.id || -1
      },
      setWorkspaceGroup: workspaceGroup => set({ workspaceGroup }),
      getProfileWorkspaceGroups: () => {
        return get().profile?.workspaces || []
      },
      getInvitations: () => {
        return get().profile?.invitations || []
      },
      getWorkspaceCertificates: () => {
        return get().workspaceGroup?.certificates || []
      },
      getCurrentWorkspace: () => {
        return get().workspaceGroup?.workspace || null
      },
      getAuthorizedFeatures: () => {
        return get().workspaceGroup?.collaborator?.authorizedFeatures || []
      },
      hasAuthorizedFeature: feature => {
        return get().getAuthorizedFeatures().includes(feature)
      },
      getCurrentWorkspaceId: () => {
        return get().workspaceGroup?.workspace.id || 0
      },
    }),
    {
      name: "profile",
    },
  ),
)
